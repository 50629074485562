<template>
  <section>
    <b-modal :active.sync="isModalActive" has-modal-card>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t('edit_metadata') }}</p>
        </header>
        <section class="modal-card-body">
          <div>
            <div>
              <transition
                name="custom-classes-transition"
                enter-active-class="animated fadeInUp"
                leave-active-class="animated fadeOutDown">
                <div class="">
                  <div class="w-full flex flex-wrap">
                    <div class="panel">
                      <form @submit.prevent="update" class="w-full" v-if="projectRuleSelectable">
                        <div class="">
                          <label class="w-full label">{{ $t('project_rule') }}</label>
                          <span class="w-full">{{ projectRuleSelectable.project_rule.name }}</span>
                        </div>

                        <div class="flex flex-wrap p-2 mt-2" v-if="metadatas.length" style="background: #eee; border-radius:.3em">
                          <div v-for="(metadata, i) in metadatas" class="w-1/2 pr-2 mb-2">
                            <label for="value" class="w-full label">
                              {{ $t(metadata.name) }}
                              <b-tooltip v-if="metadata.info" :label="metadata.info" position="is-top">
                                  <b-icon pack="fas" icon="circle-info" />
                              </b-tooltip>
                            </label>
                            
                            <b-select
                              v-if="metadata.type == 'select'"
                              v-model="metadatas[i].value"
                              :placeholder="metadata.placeholder"
                              class="w-full"
                              :required="metadata.required"
                              expanded
                            >
                              <option v-for="option in metadata.data" :value="option['value']" :key="option['value']">
                                {{ option['name'] }}
                              </option>
                            </b-select>

                            <b-switch
                              v-else-if="metadata.type == 'checkbox'"
                              v-model="metadatas[i].value"
                              :required="metadata.required"
                            >{{ $t(metadata.value ? 'yes' : 'no') }}
                            </b-switch>

                            <treeselect 
                              v-else-if="metadata.type == 'treeselect' && metadata.field == 'activity'"
                              v-model="metadatas[i].value" 
                              :close-on-select="true" 
                              :options="metadata.data" 
                              :required="metadata.required"
                              @select="loadActivityFolders"
                            />

                            <treeselect 
                              v-else-if="metadata.type == 'treeselect' && metadata.field != 'activity'"
                              v-model="metadatas[i].value" 
                              :close-on-select="true" 
                              :required="metadata.required"
                              :options="metadata.data" 
                            />

                            <v-input
                              v-else
                              v-model="metadatas[i].value"
                              :placeholder="metadata.placeholder"
                              class="w-full"
                              :required="metadata.required"
                            />
                          </div>                        
                        </div>

                        <div class="inline-flex mt-2">
                          <v-button
                            native-type="submit"
                            :disabled="metadatas.some(m => m == '' || m == null)"
                          >Actualizar
                          </v-button>
                        </div>
                      </form>
                    </div>
                  </div>

                </div>
              </transition>
            </div>
          </div>
        </section>
        <div class="modal-card-foot">
          <button class="button" :disabled="isLoading" v-on:click="isModalActive = false"><i class="fas fa-times"></i>&nbsp; {{ $t('close') }}</button>
        </div>
      </div>
      <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>

    </b-modal>
  </section>
</template>

<script>

  import {mapGetters} from 'vuex'
  import axios from "axios";
  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'

  export default {

    components: {
      Treeselect
    },

    props: {
      statusList: {type: Array, required: true},
      tasks: {type: Array, required: true},
      activities: {type: Array, required: true},
      templates: {type: Array, required: true},
      currentFolders: {type: Array, required: false}
    },

    data() {
      return {
        isModalActive: false,
        isLoading: false,
        metadatas: [],
        projectRuleSelectable: null,
        isFirst: true,
        validationStatus: [
          { name: 'Creado', value: 'created' },
          { name: 'Completado', value: 'completed' },
        ]
      }
    },

    watch: {
    },

    created(){
    },

    computed: {
    },

    methods: {
      show(projectRuleSelectable) {
        this.projectRuleSelectable = projectRuleSelectable
        this.loadMetadata(projectRuleSelectable)
        if (projectRuleSelectable.metadata.activity) {
          let findActivity = this.findActivity(projectRuleSelectable.metadata.activity)
          if (findActivity) {
            this.isFirst = true
            this.loadActivityFolders(findActivity)
          }
        }
        this.isModalActive = true
      },

      hide() {
        this.projectRuleSelectable = null
        this.isModalActive = false
      },

      async update() {
        let body = {
          metadata: {}
        }
        this.metadatas.map(m => {
          body.metadata[m.field] = m.value
        })
        this.isLoading = true;
        let {data} = await axios.post(`/api/v2/project-rule-selectable/${this.projectRuleSelectable.id}`, body)
        if (data.success) {
          this.$notify.success('success_editing')
          this.$emit('reload')
          this.hide()
        } else {
          this.$notify.error('error_saving')
        }
        this.isLoading = false;
      },

      findActivity(code) {
        for (let activity of this.activities) {
          if (activity.id == code) {return activity}
          for (let subactivity of activity.children) {
            if (subactivity.id == code) {return activity}
          }
        }
        return false
      },

      async loadActivityFolders(node) {
        const { data } = await axios.get("/api/v2/activity/"+node.activityId, {'params' : { with_folders: 1}});
        for (let i = 0; i < this.metadatas.length; i++) {
          if (this.metadatas[i].field == 'folder' && this.metadatas[i].type == 'treeselect') {
            if (!this.isFirst || !this.metadatas[i].value) {
              this.metadatas[i].value = null
            }
            this.metadatas[i].data = this.getFolders(data.data.folders)
            return
          }
        }
        this.isFirst = false
      },

      getFolders(folders) {

        var map = {}, node, roots = [], i;

        for (i = 0; i < folders.length; i += 1) {
          map[folders[i].id] = i; // initialize the map
          folders[i].children = []; // initialize the children
        }

        for (i = 0; i < folders.length; i += 1) {
          node = folders[i];
          if (node.parent_id !== null) {
            folders[map[node.parent_id]].children.push({id: node.id, label: node.name, children: node.children});
          } else {
            roots.push({id: node.id, label: node.name, children: node.children});
          }
        }

        this.cleanEmptyChildrens(roots)

        return roots;
      },

      // limpia los nodos eliminando la key 'children' si está vacio
      cleanEmptyChildrens(tree) {

        for (let i = 0; i < tree.length; i += 1) {

          // Caso base 1. No tiene la key 'children', do nothing
          if (!tree[i].children)
            return true

          // Caso base 2. Tiene la key vacia, eliminarla
          if (tree[i].children && tree[i].children.length == 0) {
            delete tree[i].children
            return true;

          // tiene childrens, pues llamar recursivamente
          } else {
            this.cleanEmptyChildrens(tree[i].children)
          }
        }
        return true

      },

      loadMetadata(projectRuleSelectable) {
        this.metadatas = []
        switch (projectRuleSelectable.project_rule.code) {
          case 'sendMailCompletion':
            this.metadatas = [
              {
                name: 'Correos',
                field: 'emails',
                value: projectRuleSelectable.metadata.emails,
                placeholder: 'Correos a los que enviar email',
                required: true
              },
              {
                name: 'Mensaje',
                field: 'message',
                value: projectRuleSelectable.metadata.message,
                placeholder: 'Cuerpo de mensaje',
                required: true
              },
              {
                name: 'Agregar documentos',
                field: 'with_documents',
                value: projectRuleSelectable.metadata.with_documents,
                placeholder: 'Incluir documentos en correo (1,0)',
                required: false,
                type: 'checkbox',
              }
            ]              
            break;
          case 'periodicCreation':
            this.metadatas = [
              {
                name: 'Día',
                field: 'day_month',
                value: projectRuleSelectable.metadata.day_month,
                placeholder: '01',
                required: true
              },
              {
                name: 'Seleccione actividad',
                field: 'activity',
                value: projectRuleSelectable.metadata.activity,
                placeholder: 'Seleccione actividad o subactividad',
                required: true,
                type: 'treeselect',
                data: this.activities
              },
              {
                name: 'Carpeta (Opcional)',
                field: 'folder',
                value: projectRuleSelectable.metadata.folder,
                placeholder: 'carpeta para documentos',
                required: false,
                type: 'treeselect',
                data: this.currentFolders
              }
            ]              
            break;
          case 'uncompletedFlowLock':
            this.metadatas = [
              {
                name: 'Flujo de validación',
                field: 'template_code',
                value: projectRuleSelectable.metadata.template_code,
                placeholder: 'Seleccione flujo de validación',
                required: true,
                type: 'treeselect',
                data: this.templates,
                info: 'Flujo de validación que tiene que completarse antes'
              },
              {
                name: 'Seleccione actividad',
                field: 'activity',
                value: projectRuleSelectable.metadata.activity,
                placeholder: 'Seleccione actividad o subactividad',
                required: true,
                type: 'treeselect',
                data: this.activities
              },
              {
                name: 'Estado a validar',
                field: 'status',
                value: `${projectRuleSelectable.metadata.status}`,
                placeholder: 'Selecciona estado',
                required: true,
                type: 'select',
                data: this.validationStatus
              },
            ]              
            break;
          case 'changeActivityStatusTaskCompletion':
            this.metadatas = [
              {
                name: 'Seleccione actividad',
                field: 'activity',
                value: projectRuleSelectable.metadata.activity,
                placeholder: 'Seleccione actividad o subactividad',
                required: true,
                type: 'treeselect',
                data: this.activities
              },
              {
                name: 'Tarea',
                field: 'task_code',
                value: `${projectRuleSelectable.metadata.task_code}`,
                placeholder: 'Selecciona tarea',
                required: true,
                type: 'treeselect',
                data: this.tasks
              },
              {
                name: 'Estado de actividad',
                field: 'status',
                value: `${projectRuleSelectable.metadata.status}`,
                placeholder: 'Selecciona estado',
                required: true,
                type: 'select',
                data: this.statusList
              }
            ]
            break;
          case 'automaticFlowCreation':
            this.metadatas = [
              {
                name: 'Código del flujo',
                field: 'template_code',
                value: projectRuleSelectable.metadata.template_code,
                placeholder: 'Código de flujo de validación',
                required: true
              },
              {
                name: 'Código actividad (sin prefijos)',
                field: 'activity',
                value: projectRuleSelectable.metadata.activity,
                placeholder: 'Código de actividad o subactividad',
                required: true
              },
              {
                name: 'Carpeta (Opcional)',
                field: 'folder',
                value: projectRuleSelectable.metadata.folder,
                placeholder: 'Folder1/Folder2/...',
                required: false
              }
            ]
            break;
        
          default:
            break;
        }
      }
    }
}
</script>

<style>
  .animation-content {
    width: 50%;
  }
  @media (max-width: 992px) {
    .animation-content {
      width: 90%;
    }
  }
</style>
