<template>
  <section>
    <b-modal :active.sync="isModalActive" has-modal-card>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t('edit_project_rules') }}</p>
        </header>
        <section class="modal-card-body">
          <div>
            <div>
              <transition
                name="custom-classes-transition"
                enter-active-class="animated fadeInUp"
                leave-active-class="animated fadeOutDown">
                <div class="">
                  <div>
                    <b>{{$t('validation')}}: </b> {{ flowTemplate.name }}
                  </div>
                  <div class="w-full flex flex-wrap">
                    <div class="panel">
                      <form @submit.prevent="create" class="w-full">
                        <div class="">
                          <label for="rule" class="w-full label">{{ $t('project_rule') }}</label>
                          <b-select ref="input"
                            v-model="item.project_rule_id"
                            :placeholder="$t('select_project_rule')"
                            id="rule"
                            name="rule"
                            class="w-full"
                            required
                            expanded
                            @input="loadMetadata()"
                          >
                            <option v-for="option in rulesList" :value="option['id']" :key="option['id']">
                              {{ option['name'] }}
                            </option>
                          </b-select>
                        </div>

                        <div class="flex flex-wrap p-2 mt-2" v-if="metadatas.length" style="background: #eee; border-radius:.3em">
                          <div v-for="(metadata, i) in metadatas" class="w-1/2 pr-2 mb-2">
                            <label for="value" class="w-full label">
                              {{ $t(metadata.name) }}
                              <b-tooltip v-if="metadata.info" :label="metadata.info" position="is-top">
                                  <b-icon pack="fas" icon="circle-info" />
                              </b-tooltip>
                            </label>
                            
                            <b-select
                              v-if="metadata.type == 'select'"
                              v-model="metadatas[i].value"
                              :placeholder="metadata.placeholder"
                              class="w-full"
                              :required="metadata.required"
                              expanded
                            >
                              <option v-for="option in metadata.data" :value="option['value']" :key="option['value']">
                                {{ option['name'] }}
                              </option>
                            </b-select>

                            <b-switch
                              v-else-if="metadata.type == 'checkbox'"
                              v-model="metadatas[i].value"
                              :required="metadata.required"
                            >{{ $t(metadata.value ? 'yes' : 'no') }}
                            </b-switch>

                            <treeselect 
                              v-else-if="metadata.type == 'treeselect' && metadata.field == 'activity'"
                              v-model="metadatas[i].value" 
                              :close-on-select="true" 
                              :options="metadata.data" 
                              :required="metadata.required"
                              @select="loadActivityFolders"
                            />

                            <treeselect 
                              v-else-if="metadata.type == 'treeselect' && metadata.field != 'activity'"
                              v-model="metadatas[i].value" 
                              :close-on-select="true" 
                              :required="metadata.required"
                              :options="metadata.data" 
                            />

                            <v-input
                              v-else
                              v-model="metadatas[i].value"
                              :placeholder="metadata.placeholder"
                              class="w-full"
                              :required="metadata.required"
                            />
                          </div>                        
                        </div>

                        <div class="inline-flex mt-2">
                          <v-button
                            native-type="submit"
                            :disabled="!item.project_rule_id"
                          >Agregar
                          </v-button>
                        </div>
                      </form>
                    </div>
                  </div>

                  <b-table
                    show-empty
                    :data="data"
                    :loading="isLoading"
                    paginated
                    striped
                    bordered
                    icon-pack="fas"
                  >
                    <template v-slot:empty="">
                      <h4>{{ $t('no_rows') }}</h4>
                    </template>

                    <template>
                      <b-table-column field="project_rule" >
                        <template v-slot:header="">
                          {{ $t('project_rule') }}
                        </template>
                        <template v-slot="props">
                          <div>
                            {{ props.row.project_rule.name }}
                          </div>
                        </template>
                      </b-table-column>

                      <b-table-column field="actions"  centered width="80">
                        <template v-slot:header="">
                          {{ $t('actions') }}
                        </template>
                        <template v-slot="props">

                          <div class="w-full h-full flex items-center justify-around">
                              <div>
                                <b-tooltip :label="$t('edit')" position="is-left">
                                  <div @click="edit(props.row)" class="cursor-pointer flex text-blue">
                                    <b-icon pack="fas" icon="pencil-alt"/>
                                  </div>
                                </b-tooltip>
                                <b-tooltip
                                  :label="$t('remove')"
                                  type="is-danger"
                                  position="is-left"
                                >
                                  <div @click="remove(props.row)" class="cursor-pointer flex text-red">
                                    <b-icon pack="fas" icon="trash-alt"/>
                                  </div>
                                </b-tooltip>
                              </div>

                          </div>
                        </template>
                      </b-table-column>
                    </template>
                  </b-table>

                </div>
              </transition>
            </div>
          </div>
        </section>
        <div class="modal-card-foot">
          <button class="button" :disabled="isLoading" v-on:click="isModalActive = false"><i class="fas fa-times"></i>&nbsp; {{ $t('close') }}</button>
        </div>
      </div>
      <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>

      <edit-entity-project-rules-modal
        ref="edit_entity_project_rules_modal"
        :status-list="statusList"
        :tasks="tasks"
        :activities="activities"
        :templates="templatesList"
        :currentFolders="currentFolders"
        @reload="() => reload()"
      />

    </b-modal>
  </section>
</template>

<script>

  import {mapGetters} from 'vuex'
  import axios from "axios";
  import EditEntityProjectRulesModal from './edit-entity-project-rules-modal'
  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'

  export default {

    components: {
      EditEntityProjectRulesModal,
      Treeselect
    },

    props: {
      projectId: {type: Number}
    },

    data() {
      return {
        isModalActive: false,
        isLoading: false,
        rulesList: [],
        data: [],
        flowTemplate: {},
        item: {},
        editItem: {},
        metadatas: [],
        statusList: [],
        tasks: [],
        activities: [],
        currentFolders: [],
        templatesList: [],
        validationStatus: [
          { name: 'Creado', value: 'created' },
          { name: 'Completado', value: 'completed' },
        ]
      }
    },

    computed: {
      ...mapGetters({
        company: 'app/company',
      }),
    },

    watch: {
    },

    created(){
      this.getProjectRules()
      this.getStatusList()
      this.loadActivities()
    },

    methods: {
      show(flowTemplate) {
        this.flowTemplate = flowTemplate
        this.getTasksList()
        this.getTemplates()
        this.isModalActive = true
        this.reload()
      },

      hide() {
        this.flowTemplate = {}
        this.isModalActive = false
      },

      async getProjectRules() {
        let {data} = await axios.get('/api/v2/project-rules', {params: {p: this.projectId}});

        if (data.success) {
          this.rulesList = data.data
        } else {
          this.$notify.error("error_loading_data");
        }
      },

      async getAll() {
        let {data} = await axios.get('/api/v2/project-rule-selectables', {params: {p: this.projectId, template: this.flowTemplate.id}});

        if (data.success) {
          this.data = data.data
        } else {
          this.$notify.error("error_loading_data");
        }
      },

      reload() {
        this.isLoading = true;
        this.getAll();
        this.isLoading = false;
      },

      async create() {
        this.item.template_id = this.flowTemplate.id
        this.item.metadata = {}
        this.metadatas.map(m => {
          this.item.metadata[m.field] = m.value
        })

        this.isLoading = true;
        let {data} = await axios.post('/api/v2/project-rule-selectable', this.item)
        if (data.success) {
          this.$notify.success('save_successful')
          this.getAll();
          this.item = {}
          this.metadatas = []
        } else {
          this.$notify.error('error_saving')
        }
        this.isLoading = false;
      },

      async edit(item) {
        this.$refs.edit_entity_project_rules_modal.show(item)
      },

      async remove(item) {
        
        this.$dialog.confirm({
          title: this.$t("delete"),
          message: this.$t("delete_confirm_text", ['la regla de proyecto ', item.project_rule.name]),
          confirmText: this.$t("delete"),
          type: "is-danger",
          hasIcon: true,
          onConfirm: async () => {
            this.isLoading = true;
            let url = `/api/v2/project-rule-selectable/${item.id}`;
            const { data } = await axios.delete(url);

            if (data.success) {
              this.$toast.open({
                message: this.$t("removed_successfully"),
                type: "is-success",
                position: "is-top-right"
              });

              this.getAll();
            } else {
              // Error
              this.$toast.open({
                message: this.$t("remove_error"),
                type: "is-danger",
                position: "is-top-right"
              });
            }
            this.isLoading = false;
          }
        });
      },

      async getStatusList() {
        let data = await axios.get('/api/v2/project/' + this.projectId + '/statuses');

        if (data.data && data.data.success) {
          this.statusList = data.data.statuses.map(status => ({
            name: status.name,
            value: status.id
          }));
        }
      },

      async getTasksList() {
        let {data} = await axios.get('/api/v2/validations/template/' + this.flowTemplate.id)
        this.tasks = []
        if (data.data && data.success) {
          this.tasks = data.data.tasks.map(task => {
            return {
              id: task.code,
              label: task.name,
              children: task.children.map(child => {
                return {
                  id: child.code,
                  label: child.name
                }
              })
            }
          })
        }
        this.loadMetadata()
      },

      async getTemplates() {
        const { data } = await axios.get("/api/v2/validations/templateImportsList", {'params' : { company_id: this.company.id }});
        if (data.templates) {
          this.templatesList = data.templates.filter(t => this.flowTemplate.id != t.id).map(template => {
            return {
              id: template.code,
              label: template.name
            }
          });
        }
      },

      async loadActivities() {
      const { data } = await axios.get("/api/v2/activities", {'params' : { p : this.projectId, tree: 1}});
        this.activities = data.activities.map(activity => {
          return {
            id: activity.planning_code,
            activityId: activity.id,
            label: activity.name,
            children: activity.children_activities.map(subactivity => {
              return {
                id: subactivity.planning_code,
                activityId: subactivity.id,
                label: subactivity.name
              }
            })
          }
        });
      },

      async loadActivityFolders(node) {
        const { data } = await axios.get("/api/v2/activity/"+node.activityId, {'params' : { with_folders: 1}});
        for (let i = 0; i < this.metadatas.length; i++) {
          if (this.metadatas[i].field == 'folder' && this.metadatas[i].type == 'treeselect') {
            this.metadatas[i].value = null
            this.currentFolders = this.getFolders(data.data.folders)
            this.metadatas[i].data = this.currentFolders
            return
          }
        }
      },

      getFolders(folders) {

        var map = {}, node, roots = [], i;

        for (i = 0; i < folders.length; i += 1) {
          map[folders[i].id] = i; // initialize the map
          folders[i].children = []; // initialize the children
        }

        for (i = 0; i < folders.length; i += 1) {
          node = folders[i];
          if (node.parent_id !== null) {
            folders[map[node.parent_id]].children.push({id: node.id, label: node.name, children: node.children});
          } else {
            roots.push({id: node.id, label: node.name, children: node.children});
          }
        }

        this.cleanEmptyChildrens(roots)

        return roots;
      },

      // limpia los nodos eliminando la key 'children' si está vacio
      cleanEmptyChildrens(tree) {

        for (let i = 0; i < tree.length; i += 1) {

          // Caso base 1. No tiene la key 'children', do nothing
          if (!tree[i].children)
            return true

          // Caso base 2. Tiene la key vacia, eliminarla
          if (tree[i].children && tree[i].children.length == 0) {
            delete tree[i].children
            return true;

          // tiene childrens, pues llamar recursivamente
          } else {
            this.cleanEmptyChildrens(tree[i].children)
          }
        }
        return true

      },

      loadMetadata() {
        let findRule = this.rulesList.find(r => r.id == this.item.project_rule_id)
        this.metadatas = []
        if (findRule) {
          switch (findRule.code) {
            case 'sendMailCompletion':
              this.metadatas = [
                {
                  name: 'Correos',
                  field: 'emails',
                  value: '',
                  placeholder: 'Correos a los que enviar email',
                  required: true
                },
                {
                  name: 'Mensaje',
                  field: 'message',
                  value: '',
                  placeholder: 'Cuerpo de mensaje',
                  required: true
                },
                {
                  name: 'Agregar documentos',
                  field: 'with_documents',
                  value: false,
                  placeholder: 'Incluir documentos en correo (1,0)',
                  required: false,
                  type: 'checkbox',
                }
              ]              
              break;
            case 'periodicCreation':
              this.metadatas = [
                {
                  name: 'Día',
                  field: 'day_month',
                  value: '',
                  placeholder: '01',
                  required: true
                },
                {
                  name: 'Seleccione actividad',
                  field: 'activity',
                  value: null,
                  placeholder: 'Seleccione actividad o subactividad',
                  required: true,
                  type: 'treeselect',
                  data: this.activities
                },
                {
                  name: 'Carpeta (Opcional)',
                  field: 'folder',
                  value: null,
                  placeholder: 'carpeta para documentos',
                  required: false,
                  type: 'treeselect',
                  data: []
                }
              ]              
              break;
            case 'uncompletedFlowLock':
              this.metadatas = [
                {
                  name: 'Plantilla de validación',
                  field: 'template_code',
                  value: null,
                  placeholder: 'Seleccione plantilla de flujo de validación',
                  required: true,
                  type: 'treeselect',
                  data: this.templatesList,
                  info: 'Flujo de validación que tiene que completarse antes'
                },
                {
                  name: 'Seleccione actividad',
                  field: 'activity',
                  value: null,
                  placeholder: 'Seleccione actividad o subactividad',
                  required: true,
                  type: 'treeselect',
                  data: this.activities
                },
                {
                  name: 'Estado a validar',
                  field: 'status',
                  value: null,
                  placeholder: 'Selecciona estado',
                  required: true,
                  type: 'select',
                  data: this.validationStatus
                },
              ]              
              break;
            case 'changeActivityStatusTaskCompletion':
              this.metadatas = [
                {
                  name: 'Seleccione actividad',
                  field: 'activity',
                  value: null,
                  placeholder: 'Seleccione actividad o subactividad',
                  required: true,
                  type: 'treeselect',
                  data: this.activities
                },
                {
                  name: 'Tarea',
                  field: 'task_code',
                  value: null,
                  placeholder: 'Selecciona tarea',
                  required: true,
                  type: 'treeselect',
                  data: this.tasks
                },
                {
                  name: 'Estado de actividad',
                  field: 'status',
                  value: null,
                  placeholder: 'Selecciona estado',
                  required: true,
                  type: 'select',
                  data: this.statusList
                },
              ]
              break;
            case 'automaticFlowCreation':
              this.metadatas = [
                {
                  name: 'Código de validación',
                  field: 'template_code',
                  value: '',
                  placeholder: 'Código de plantilla de flujo de validación',
                  required: true
                },
                {
                  name: 'Código actividad (sin prefijos)',
                  field: 'activity',
                  value: '',
                  placeholder: 'Código de actividad o subactividad',
                  required: true
                },
                {
                  name: 'Carpeta (Opcional)',
                  field: 'folder',
                  value: '',
                  placeholder: 'Folder1/Folder2/...',
                  required: false
                }
              ]
              break;
          
            default:
              break;
          }
        }
      }
    }
}
</script>

<style>
  .animation-content {
    width: 50%;
  }
  @media (max-width: 992px) {
    .animation-content {
      width: 90%;
    }
  }
</style>
