<template>
  <div class="flex-col">
    <div class="w-full mt-2 panel">
      <div class="flex flex-row w-full text-blue font-bold">Fecha del Informe</div>
      <p class="text-gray-400 text-sm mb-2">Ingrese la semana y la fecha que corresponde a esa semana del informe</p>

      <div class="flex space-x-2">
        <div class="w-1/3">
          <input
          v-model="week" class="input"
          type="text" :placeholder="$t('week_of_the_year')">
        </div>

        <div class="w-1/3">
          <b-datepicker
            :placeholder="$t('select_period')"
            v-model="weeklyRangeForm"
            :first-day-of-week="parseInt($t('first_day_of_week'))"
            icon-pack="fas"
            range
            >
          </b-datepicker>
        </div>
      </div>
    </div>

    <div class="w-full mt-4 panel">
      <div class="flex flex-row w-full text-blue font-bold">{{ $t('report_sections') }}</div>
      <p class="text-gray-400 text-sm mb-2">Selecciones los apartados que desea que salga en el informe</p>
      <b-field>
        <div v-for="(tab, index) in report_sections" :key="'switch_report_section'+index">
          <b-switch
            v-if="tab.name != 'General'"
            v-model="tab.print" type="is-primary" size="is-small">
            {{ tab.name }}
          </b-switch>
        </div>
      </b-field>
    </div>

    <b-tabs type="is-boxed" v-model="mainActiveTab">
      <!-- report sections -->
      <b-tab-item v-for="(tab, index) in report_sections" :key="'report_section_'+index">
        <template slot="header">
          <b-icon pack="fas" icon="tasks"></b-icon>
          <span> {{ tab.name }} </span>
        </template>
        <div>
          <div class="w-full mt-4 panel">
            <div v-if="tab.progress_scheduled != undefined && tab.progress_scheduled != null" class="w-full mb-3">
              <div class="flex flex-row w-full text-blue font-bold">{{ $t('progress_scheduled_plant_photovoltaic') }}</div>
              <div class="flex flex-row w-3/3 border border-gray-300 rounded-lg p-2 bg-white shadow-md mt-3">
                <div v-for="progress in tab.progress_scheduled" class="w-2/5 my-2">
                  <div class="inline-block w-4/5">
                    <b-field :label="$t(progress.name)">
                      <b-input v-model="progress.value" type="is-primary"></b-input>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-row w-full text-blue font-bold">{{ $t('images') }}</div>
            <div class="w-full">

              <div v-if="tab.with_drawing" class="flex flex-row w-3/3 border border-gray-300 rounded-lg p-2 bg-white shadow-md mt-3">
                <div class="w-1/5">
                  <img :src="getImagePreview({image: tab.drawing_image}, true)" class="inline-block h-28" alt="">
                </div>
                <div class="w-2/5 ">
                </div>

                <div v-if="tab.drawing_image" class="w-2/5 m-2 flex items-center" style="">
                  <div class="w-4/5">
                    <b-button type="is-danger" @click="deleteImage(tab.id, null, 'drawing')" class="w-full">
                      <b-icon pack="fas" icon="trash-alt"></b-icon>
                      <span>{{ $t('remove') }}</span>
                    </b-button>
                  </div>
                </div>

                <div v-else class="w-2/5 m-2">
                  <div class="w-4/5 mt-2">
                    <b-button type="is-primary" @click="openModalActivityImage(index, null, 'drawing', 'drawing')" class="w-full">
                      <b-icon pack="fas" icon="search"></b-icon>
                      <span>{{ $t('select_drawing') }}</span>
                    </b-button>
                  </div>

                  <div class="w-4/5 mt-2">
                    <b-button type="is-primary" @click="openModalActivityImage(index, null, 'drawingActivity')" class="w-full">
                      <b-icon pack="fas" icon="search"></b-icon>
                      <span>{{ $t('select_activity') }}</span>
                    </b-button>
                  </div>
                </div>
              </div>

              <div v-if="tab.with_activity_document" class="flex flex-row w-3/3 border border-gray-300 rounded-lg p-2 bg-white shadow-md mt-3">
                <div class="w-1/5">
                  <img :src="getImagePreview({image: tab.activity_image}, true)" class="inline-block h-28" alt="">
                </div>
                <div class="w-2/5 ">
                </div>

                <div v-if="tab.activity_image" class="w-2/5 m-2 flex items-center" style="">
                  <div class="w-4/5">
                    <b-button type="is-danger" @click="deleteImage(tab.id, null, 'activity')" class="w-full">
                      <b-icon pack="fas" icon="trash-alt"></b-icon>
                      <span>{{ $t('remove') }}</span>
                    </b-button>
                  </div>
                </div>

                <div v-else class="w-2/5 m-2">
                  <div class="w-4/5 mt-2">
                    <b-button type="is-primary" @click="openModalActivityImage(index, null, 'activity')" class="w-full">
                      <b-icon pack="fas" icon="search"></b-icon>
                      <span>{{ $t("select_activity") }}</span>
                    </b-button>
                  </div>
                </div>
              </div>

              <!-- color picker and input text for status_oc name and color -->
              <div v-if="tab.with_status">
                <div class="w-full border border-gray-300 rounded-lg p-2 bg-white shadow-md mt-3">
                  <div class="flex flex-row">
                    <div class="w-1/5 m-2">
                      <section>
                          <b-field label="">
                              <b-colorpicker
                              v-model="colorSelected"
                              />
                          </b-field>
                      </section>
                    </div>

                    <div class="w-2/5 my-2">
                      <div class="inline-block w-4/5">
                        <b-input v-model="nameStatusTemp" type="is-primary" placeholder="Nombre del estado"></b-input>
                      </div>
                    </div>
                    <!-- 2 buttom add status ando show statuses-->
                    <div class="w-2/5 m-2">
                      <b-button type="is-primary" color="blue" @click="addStatus(index)">
                        <b-icon pack="fas" icon="plus"></b-icon> <span>{{ $t('add_status') }}</span>
                      </b-button>

                    </div>
                  </div>
                  <div class="w-full mt-3">
                    <div class="w-full">
                      <div v-for="(status, index) in tab.status_oc" :key="'status_'+index" class="inline-block m-2">
                        <div class="flex flex-row mb-1 bg-grey-light border hover:border-blue rounded p-1">

                          <div class="flex items-stretch">
                            <b-icon
                              :icon="status.is_completion ? 'check-circle' : 'square-full'"
                              pack="fas"
                              :style="{ color: status.color }"
                              style="height: 2rem !important; width: 2rem !important;"
                              size="is-medium">
                            </b-icon>
                          </div>

                          <div class="flex-grow text-md mr-1 ml-1"><p>{{ status.name }}</p></div>

                          <div>
                            <b-button type="is-danger" @click="tab.status_oc.splice(index, 1)">
                              <b-icon

                              pack="fas" icon="trash-alt"></b-icon>
                            </b-button>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- image viewer from tab.images-->
              <div v-for="(image, imgindex) in tab.images" :key="'image_'+imgindex" class="flex flex-row w-3/3 border border-gray-300 rounded-lg p-2 bg-white shadow-md mt-3">
                <div class="w-1/5">
                  <img :src="getImagePreview(image)" class="inline-block h-28" :alt="$t('select_image')">
                </div>
                <div class="w-2/5 ">
                  <!-- nombre imagen -->
                  <div class="inline-block m-2 w-4/5">
                    <div class="flex flex-row w-full text-blue font-bold">{{ $t('image') }}
                      {{ imgindex + 1 }}
                    </div>
                    <b-input v-model="image.name" type="is-primary" :placeholder="$t('name_image')"></b-input>
                  </div>
                </div>

                <div v-if="image.image" class="w-2/5 m-2 flex items-center" style="">
                  <div class="w-4/5">
                    <b-button type="is-danger" @click="deleteImage(tab.id, imgindex)" class="w-full">
                      <b-icon pack="fas" icon="trash-alt"></b-icon>
                      <span>{{ $t('remove') }}</span>
                    </b-button>
                  </div>
                </div>

                <div v-else class="w-2/5 m-2">
                  <div class="w-4/5">
                    <b-button type="is-primary" @click="uploadImage(tab.id, imgindex)" class="w-full">
                      <b-icon pack="fas" icon="search"></b-icon>
                      <span>{{ $t('select_image') }}</span>
                    </b-button>
                  </div>

                  <div class="w-4/5 mt-2">
                    <b-button type="is-primary" @click="openModalActivityImage(index, imgindex)" class="w-full">
                      <b-icon pack="fas" icon="search"></b-icon>
                      <span>{{ $t('select_activity') }}</span>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </b-tab-item>
    </b-tabs>

    <div class="w-full text-center">
      <b-button type="is-success" color="green" @click="exportReport" :loading="isLoadingExport" :tooltip="txtTooltipExport">
        <b-icon pack="fas" icon="file-export"></b-icon> <span>{{ $t('generate_report') }}</span>
      </b-button>
      <b-button type="is-primary" color="blue" @click="exportExcelCurrentProgress" :loading="isLoadingExportExcel" :tooltip="txtTooltipExport">
        <b-icon pack="fas" icon="file-export"></b-icon> <span>{{ $t('generate_only_excel') }}</span>
      </b-button>
    </div>

    <!-- modal with input image for upload Images -->
    <b-modal v-model="showModal" title="Subir imagen">
      <div class="w-full bg-gray-50 p-4 flex justify-center items-center rounded-lg shadow-md" style="display:grid">
        <b-field class="inline-block">
          <b-upload v-model="dropFileTemp" drag-drop expanded accept="image/*">
            <section class="section">
              <div class="content has-text-centered">
                <p>
                  <b-icon icon="upload" size="is-large"></b-icon>
                </p>
                <p>{{ $t('drag_file_or_click_select') }}</p>
              </div>
            </section>
          </b-upload>
        </b-field>

        <b-input class="inline-block" v-model="uploadImageName"
          type="is-primary" placeholder="Nombre de la imagen">
        </b-input>

        <b-button type="is-primary" class="mt-2" @click="cargarImagen">
          <b-icon pack="fas" icon="upload"></b-icon>
          <span>{{ $t('upload_image') }}</span>
        </b-button>

      </div>
    </b-modal>

    <!-- modal with input image for upload Images -->
    <b-modal v-model="showModalActivityImage" :title="$t('upload_image')">
      <div class="w-full bg-gray-50 p-5 flex justify-center items-center rounded-lg shadow-md">

        <div class="w-1/2 m-4 flex justify-center">
          <img :src="activityImage.url || asset('public/img_placeholder.png')" style="height: 300px">
        </div>

        <div class="w-1/2 m-4">

          <div class="w-full mb-4">
            <b-select
                v-model="activityImage.activity_id"
                :placeholder="$t('select_activity')"
                icon="clipboard-list"
                icon-pack="fas"
                expanded
                @input="manageSelectedActivityModal()"
            >
              <option v-for="(option, index) in activities" :value="option['id']" :key="'activity_modal_'+index">
                {{ option['name'] }}
              </option>

            </b-select>
          </div>
          <div class="w-full mb-4">
            <b-select
                v-model="activityImage.subactivity_id"
                :placeholder="$t('select_subactivity')"
                icon="clipboard-list"
                icon-pack="fas"
                expanded
                @input="manageSelectedActivityModal(true)"
            >
              <option v-for="(option, index) in activityImage.subactivities" :value="option['id']" :key="'subactivity_modal_'+index">
                {{ option['name'] }}
              </option>

            </b-select>
          </div>
          <div class="w-full mb-4">
            <b-select
                v-model="activityImage.document_id"
                :placeholder="activityImage.documents.length > 0 ? 'Selecciona documento' : 'No existe imagenes'"
                icon="clipboard-list"
                icon-pack="fas"
                expanded
                @input="loadModalImagePreview"
            >
              <option v-for="(option) in activityImage.documents" :value="option['document_id']" :key="'document_modal_'+option['document_id']">
                {{ option['name'] }}
              </option>

            </b-select>
          </div>
          <b-button type="is-primary" class="w-full" @click="saveActivityImage">
            <b-icon pack="fas" icon="upload"></b-icon>
            <span>Subir imagen</span>
          </b-button>
        </div>

      </div>
    </b-modal>

    <!-- modal with input image for upload Images -->
    <b-modal v-model="showModalDrawingImage" title="Subir imagen">
      <div class="w-full bg-gray-50 p-5 flex justify-center items-center rounded-lg shadow-md">

        <div class="w-1/2 m-4 flex justify-center">
          <img :src="activityImage.url || asset('public/img_placeholder.png')" style="height: 300px">
        </div>

        <div class="w-1/2 m-4">
          <div class="w-full mb-4">
            <b-select
                v-model="activityImage.document_id"
                :placeholder="drawings.length > 0 ? 'Selecciona plano' : 'No existen planos'"
                icon="clipboard-list"
                icon-pack="fas"
                expanded
                @input="loadModalImagePreview"
            >
              <option v-for="(option) in drawings" :value="option['document_id']" :key="'drawing_modal_'+option['document_id']">
                {{ option['name'] }}
              </option>

            </b-select>
          </div>
          <b-button type="is-primary" class="w-full" @click="saveActivityImage">
            <b-icon pack="fas" icon="upload"></b-icon>
            <span>Subir imagen</span>
          </b-button>
        </div>

      </div>
    </b-modal>

    <!-- modal statuses view status_oc-->
    <b-modal
     v-model="showStatuses" title="Estados">
      <div class="w-full bg-gray-50 p-4 flex justify-center items-center rounded-lg shadow-md" style="display:grid">
        <div class="w-full">
          <div v-for="(status, index) in status_oc" :key="'status_'+index" class="flex flex-row mb-1 bg-grey-light border hover:border-blue rounded p-1">
            <div class="flex items-stretch">
              <b-icon
                :icon="status.is_completion ? 'check-circle' : 'square-full'"
                pack="fas"
                :style="{ color: status.color }"
                style="height: 1.5rem !important; width: 1.5rem !important;"
                size="is-medium">
              </b-icon>
            </div>
            <div class="flex-grow text-md mr-1 ml-1"><p>{{ status.name }}</p></div>
          </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import moment from 'moment'


  // import the component

  Vue.use(moment)

  export default {
    components: {

    },

    data: () => ({
      s3bucket: window.config.s3bucket,
      filters: {
        location_id: 0,
        status_id: 1,
        tag_name: '',
        parent_group_id: null
      },
      isLoading: true,
      panel1: {
        info: [
          {
            "name": "ACTIVIDADES",
            "total": 0
          },
          {
            "name": "MEDICIONES CREADAS",
            "total": 0
          },
          {
            "name": "INCIDENCIAS",
            "total": 0
          },
          {
            "name": "FLUJOS CREADOS",
            "total": 0
          },
          {
            "name": "IMÁGENES CARGADAS",
            "total": 0
          },
          {
            "name": "DOCUMENTOS CARGADOS",
            "total": 0
          },
          {
            "name": "COMENTARIOS",
            "total": 0
          }
        ],
        users: []
      },
      weeklyRangeForm: [],
      weeklyRange: [], // rango de fechas procesado
      week: null, // semana enviado para el pdf

      status_oc:[], // { name: 'status name', color: 'color',}
      colorSelected: '#7957d5',
      nameStatusTemp: '',
      showStatuses: false,

      report_sections: [],

      showModal: false,
      UploadId: null,
      uploadImageIdx: null,
      dropFileTemp: null,
      uploadImageName: null,


      mainActiveTab: 0,


      isLoadingExport: false,
      isLoadingExportExcel: false,
      txtTooltipExport: null,
      activities: [],
      drawings: [],
      showModalActivityImage: false,
      showModalDrawingImage: false,
      activityImage: {
        tabIndex: 0,
        imageIndex: 0,
        url: '',
        activity_id: null,
        subactivity_id: null,
        document_id: null,
        subactivities: [],
        documents: [],
        type: 'image'
      },
      with_pictograms: true,

      // minDate: null,
      // maxDate: null
    }),

    computed: {
      selectedCompanyAlias: function() {
        return this.company ? this.company.alias : null
      },
      ...mapGetters({
        project: 'app/project',
        user: 'auth/user',
        company: 'app/company',
        dashboardReport: 'app/dashboardReport',
      }),
    },

    created() {
      this.loadSections()
    },

    mounted() {

      if (this.project == null) {
        this.$router.push('/projects');
      } else {

        let pageInfo = {
          title: this.project.name,
          subtitle: this.$t('project_home_page_subtitle'),
          menuOption: '2-1',
        }

        this.$store.dispatch('app/updatePageInfo', {info: pageInfo})
      }

      this.getActivities()
      this.getDrawings()
      this.loadConfig()
    },

    methods: {

      async getSubactivities(activityId){
        const { data } = await axios.get("/api/v2/activity/" + activityId + "/subactivities");
        return data.subactivities;
      },

      addStatus(index) {
        if (!this.nameStatusTemp) {
          this.$notify.error('Nombre de status vacio');
          return;
        }

        let color = this.colorSelected;
        if (typeof color === 'object') {
          // Convertir objeto a hexadecimal
          color = this.rgbToHex(color.red, color.green, color.blue);
        }

        if (!this.isValidHex(color)) {
          this.$notify.error('Color no valido');
          return;
        }

        this.report_sections[index].status_oc.push({
          name: this.nameStatusTemp,
          color: color
        });
        this.nameStatusTemp = '';
      },

      isValidHex(color) {
        return /^#[0-9A-Fa-f]{6}$/i.test(color);
      },

      rgbToHex(r, g, b) {
        const toHex = c => c.toString(16).padStart(2, '0');
        return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
      },

      async getActivities(){
        const { data } = await axios.get("/api/v2/activities", {'params' : { p : this.project.id, per_page: 999}});
        this.activities = data.activities;
      },

      async getDocuments(activityId){
        const { data } = await axios.get("/api/v2/activity/" + activityId + "/documents");
        return data.documents.filter(doc => doc.type == 1 || (doc.type == 0 && doc.mime_type == 'application/pdf'));
      },

      async manageSelectedActivity(tabId, isSubactivity = false) {
        let activityId = this.report_sections[tabId].activity_id;
        if (isSubactivity) {
          activityId = this.report_sections[tabId].subactivity_id;
        } else {
          this.report_sections[tabId].subactivity_id = null;
          this.report_sections[tabId].subactivities = await this.getSubactivities(this.report_sections[tabId].activity_id);
        }

        this.report_sections[tabId].document_id = null;
        this.report_sections[tabId].documents = await this.getDocuments(activityId)
      },

      async getDrawings(){
        let params = {
          text: '',
          with: 'drawings'
        };

        const { data } = await axios.get(
          "/api/v2/project/" + this.project.id + "/documents",
          { params: params }
        );

        if (data && data.success) {
          this.drawings = data.documents;
        }
      },


      // MODAL ACTIVITY DOCUMENTS FUNCTIONS
      async openModalActivityImage(tabIndex, imageIndex, type = 'image', modal = 'activity') {
        this.activityImage = {
          tabIndex,
          imageIndex,
          url: '',
          activity_id: null,
          subactivity_id: null,
          document_id: null,
          subactivities: [],
          documents: [],
          type
        }

        if (modal == 'drawing') {
          this.showModalDrawingImage = true
        } else {
          this.showModalActivityImage = true
        }
      },

      async manageSelectedActivityModal(isSubactivity = false) {
        let activityId = this.activityImage.activity_id;
        if (isSubactivity) {
          activityId = this.activityImage.subactivity_id;
        } else {
          this.activityImage.subactivity_id = null;
          this.activityImage.subactivities = await this.getSubactivities(activityId);
        }

        this.activityImage.document_id = null;
        this.activityImage.documents = await this.getDocuments(activityId)
      },

      async saveActivityImage() {
        if (!this.activityImage.document_id) {
          this.showModalActivityImage = false
          this.showModalDrawingImage = false
          return false
        }

        let findDocument = this.activityImage.type == 'drawing'
          ? this.drawings.find(doc => doc.document_id == this.activityImage.document_id)
          : this.activityImage.documents.find(doc => doc.document_id == this.activityImage.document_id)

        if (this.activityImage.type == 'drawing' || this.activityImage.type == 'drawingActivity') {
          this.report_sections[this.activityImage.tabIndex].drawing_image = findDocument.file.big_thumbnail
        } else if (this.activityImage.type == 'activity') {
          this.report_sections[this.activityImage.tabIndex].activity_image = findDocument.file.big_thumbnail
        } else {
          this.report_sections[this.activityImage.tabIndex].images[this.activityImage.imageIndex] = {
            image: findDocument.file.big_thumbnail,
            name: findDocument.name,
            is_activity_document: true,
            key: ''
          }
        }

        this.activityImage = {
          tabIndex: 0,
          imageIndex: 0,
          url: '',
          activity_id: null,
          subactivity_id: null,
          document_id: null,
          subactivities: [],
          documents: [],
          type: 'image',
        }
        this.showModalActivityImage = false
        this.showModalDrawingImage = false
      },

      async loadModalImagePreview() {
        let findDocument = this.activityImage.type == 'drawing'
          ? this.drawings.find(doc => doc.document_id == this.activityImage.document_id)
          : this.activityImage.documents.find(doc => doc.document_id == this.activityImage.document_id)
        this.activityImage.url = findDocument.file.big_thumbnail
      },

      //

      uploadImage(tabId, imgIndex) {
        this.UploadId = tabId;
        this.uploadImageIdx = imgIndex;
        this.showModal = true
      },

      deleteImage(tabId, imageIndex, type = 'image') {
        if (type == 'drawing') {
          this.report_sections.find(tab => tab.id == tabId).drawing_image = '';
        } else if (type == 'activity') {
          this.report_sections.find(tab => tab.id == tabId).activity_image = '';
        } else {
          this.report_sections.find(tab => tab.id == tabId).images.splice(imageIndex, 1, {id: imageIndex + 1, name: 'imagen ' + (imageIndex + 1), image: null})
        }
      },

      async cargarImagen() {
        if (this.dropFileTemp) {
          if (!this.uploadImageName) {
            this.uploadImageName = this.dropFileTemp.name
          }
          // compress image
          let compressedImage = await this.resizeAndCompressImage(this.dropFileTemp, 600, 0.8)

          let image = {
            image: compressedImage,
            name: this.uploadImageName
          }
          this.report_sections.find(tab => tab.id == this.UploadId).images.splice(this.uploadImageIdx, 1, image)

          this.showModal = false
          this.dropFileTemp = null
          this.uploadImageName = null
          this.UploadId = null
          this.uploadImageIdx = null

        } else {
          this.$notify.error('error_uploading_image')
        }
      },

      getImagePreview(image, isUrl = false) {
        // console.log('getImagePreview', image)
        let url = ''
        if (image.image) {
          if (image.is_activity_document || isUrl) {
            url = image.image
          } else {
            url = URL.createObjectURL(image.image)
          }
        } else {
          url = this.asset('public/img_placeholder.png')
        }
        return url
      },

      resizeAndCompressImage(file, maxSize, quality) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = (event) => {
            const img = new Image();
            img.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');

              const ratio = Math.min(maxSize / img.width, maxSize / img.height);
              const width = img.width * ratio;
              const height = img.height * ratio;

              canvas.width = width;
              canvas.height = height;

              ctx.drawImage(img, 0, 0, width, height);

              canvas.toBlob(
                (blob) => {
                  if (blob) {
                    resolve(new File([blob], file.name, { type: blob.type }));
                  } else {
                    resolve(file);
                  }
                },
                'image/jpeg',
                quality
              );
            };
            img.src = event.target.result;
          };

          reader.onerror = (error) => {
            resolve(file);
          };

          reader.readAsDataURL(file);
        });
      },

      async exportExcelCurrentProgress(){
        if ( !this.isLoadingExportExcel ) {
          this.txtTooltipExport = this.$t('generating');

          this.$notify.success('generating_report');
          this.isLoadingExportExcel = true;
          let self = this
          let url = '/api/v2/project/' + this.project.id + '/dashboard/excel_report_current_progress';


          await axios.post(url, {infoTabs: [{infotab:"excel"}]} )
            .then(response => {
              self.$notify.success('generating_report')
              self.isLoadingExportExcel = false;
              self.txtTooltipExport = this.$t('export')
            })
            .catch((error) => {

              self.$notify.error(error.error_msg || 'error_retrieving_data')
              self.isLoadingExportExcel = false
              self.txtTooltipExport = this.$t('export')
            });

        }

      },

      async exportReport() {

        if ( !this.isLoadingExport ) {
          this.txtTooltipExport = this.$t('generating');

          // guardamos configuración
          await this.saveConfig()

          this.weeklyRange = this.weeklyRangeForm ? this.weeklyRangeForm.map(date => {
            return moment(date).format('DD-MM-YYYY')
          }) : []
          // console.log(this.report_sections[1].weeklyRange)

          this.$notify.success('generating_report');
          this.isLoadingExport = true;
          let self = this
          let url = '/api/v2/project/' + this.project.id + '/dashboard/dashboardreport';

          let infoTabs = []

          for (let reportSection of this.report_sections) {
            let reportImages = []
            if (reportSection.print) {
              for (let i = 0; i < reportSection.images.length; i++) {
                let img = reportSection.images[i]
                if (img.image) {
                  if (img.is_activity_document) {
                      reportImages.push({
                        name: img.name, key: '', image: img.image
                      })
                  } else {

                    let code = reportSection.code + '_image_' +i+ '_image_' + img.name

                    // let form = new FormData();
                    // form.append('file', img.image)
                    // form.append('key', code)

                    // no vale, POST superiores a 1MB pueden fallar (413 Entity Too Large)
                    // let {data} = await axios.post('/api/v2/s3/upload-temp?optimize=1', form)

                    let data = await this.storeImage(img.image, {})
                    // la url que devuelve aws esta dando problemas "SignatureDoesNotMatch". Usamos ruta directa a la imagen
                    data.urlManual = 'https://'+window.config.s3bucket+'/'+data.key

                    if (data) {
                      reportImages.push({
                        name: img.name, key: data.uuid, image: data.urlManual
                      })
                    }
                  }

                }
              }
            }
            infoTabs.push({...reportSection, images: reportImages, subactivities: [], documents: []})
          }

          // await axios.post(url, datapost)

          await axios.post(url, {infoTabs, weeklyRange: this.weeklyRange, week: this.week, with_pictograms: this.with_pictograms} )
            .then(response => {
              self.$notify.success('generating_report')
              this.$emit('refresh-history')
              self.isLoadingExport = false;
              self.txtTooltipExport = this.$t('export')
            })
            .catch((error) => {
              //Se produjo un error

              self.$notify.error(error.error_msg || 'error_retrieving_data')
              self.isLoadingExport = false
              self.txtTooltipExport = this.$t('export')
            });
        }
      },


      // CUSTOM VAPOR STORE METHOD
      async storeImage(file, options = null) {

        // esto ya sube automaticamente a la ruta /tmp del bucket del entorno (epc-tracker-storage / *-local / *-dev)
        const response = await axios.post('/vapor/signed-storage-url', {
            'visibility': 'public-read',
            'bucket': options.bucket || '',
            'content_type': options.contentType || file.type,
            'expires': options.expires || ''
        });

        if (typeof options.progress === 'undefined') {
            options.progress = () => {};
        }

        // This is the fix for the headers. Instance just for the S3 PUT
        var instance = axios.create();
        instance.defaults.headers.common = {};
        const s3Response = await instance.put(response.data.url, file, {
            headers: response.data.headers,
            onUploadProgress: (progressEvent) => {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
            }
        });

        response.data.extension = file.name.split('.').pop()

        return response.data;

      },

      async saveConfig() {
        let config = this.report_sections.map(tab => {
          let tabData = {
            print: tab.print
          }
          if (tab.activity_image) {
            tabData.activity_image = tab.activity_image
          }
          if (tab.drawing_image) {
            tabData.drawing_image = tab.drawing_image
          }
          if (tab.status_oc) {
            tabData.status_oc = tab.status_oc
          }
          return tabData
        })

        this.$store.dispatch('app/setDashboardReport', {dashboardReport: { report_sections: config} })
      },

      async loadConfig() {
        if (this.dashboardReport.report_sections) {
          for (let i = 0; i < this.dashboardReport.report_sections.length; i++) {
            let config = this.dashboardReport.report_sections[i]
            Object.assign(this.report_sections[i], config)
          }
        }
      },

      loadSections() {
        // console.log('loadSections: ' + this.project.id);
        /* proyectos:
            arco malpartida :         587
            CSF LORCA 205 MWp - OHLA: 593
            CSF LORCA 168 MWp - EIFF: 594
            Lorca interconection:     584
            Cubillas  :               548
            El molino :               595
            mirtea :                  537
        */
        switch (this.project.id) {

          case 584: // lorca interconection
            // LORCA
            this.report_sections = [
              {
                id: 1,
                name: "General",
                code: "",
                print: true,
                with_drawing: false,
                with_activity_document: false,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  },
                  {
                    id: 5,
                    name: this.$t('image') + " 5",
                    image: null
                  },
                  {
                    id: 6,
                    name: this.$t('image') + " 6",
                    image: null
                  },
                ]
              },
              {
                id: 2,
                name: "SET LORCA",
                code: "02",
                print: true,
                with_drawing: true,
                drawing_image: '',
                with_activity_document: false,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ],
                with_status: true,
                status_oc: []
              },
              {
                id: 3,
                name: "SET TOTANA",
                code: "03",
                print: true,
                with_drawing: true,
                drawing_image: '',
                with_activity_document: false,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ],
                with_status: true,
                status_oc: []
              },
              {
                id: 4,
                name: "Evacuation Line LSAT 132KV",
                code: "04",
                print: true,
                with_drawing: false,
                with_activity_document: true,
                activity_image: '',
                is_evacuation_line: true,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ]
              },
              {
                id: 5,
                name: "Evacuation Line LAAT 132KV",
                code: "05",
                print: true,
                with_drawing: false,
                with_activity_document: true,
                activity_image: '',
                is_evacuation_line: true,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ]
              },
              {
                id: 6,
                name: "Evacuation Line 400KV",
                code: "06",
                print: true,
                with_drawing: false,
                with_activity_document: true,
                activity_image: '',
                is_evacuation_line: true,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ]
              }
            ]
            this.with_pictograms = false
            break;
          case 548 : // cubillas
            this.report_sections = [
                {
                  id: 1,
                  name: "General",
                  code: "",
                  print: true,
                  with_drawing: false,
                  with_activity_document: false,
                  images: [
                    {
                      id: 1,
                      name: this.$t('image') + " 1",
                      image: null
                    },
                    {
                      id: 2,
                      name: this.$t('image') + " 2",
                      image: null
                    },
                    {
                      id: 3,
                      name: this.$t('image') + " 3",
                      image: null
                    },
                    {
                      id: 4,
                      name: this.$t('image') + " 4",
                      image: null
                    },
                    {
                      id: 5,
                      name: this.$t('image') + " 5",
                      image: null
                    },
                    {
                      id: 6,
                      name: this.$t('image') + " 6",
                      image: null
                    },
                  ]
                },
                {
                  id: 2,
                  name: "CIVIL WORKS",
                  code: "OC",
                  print: true,
                  with_drawing: true,
                  drawing_image: '',
                  with_activity_document: false,
                  images: [
                    {
                      id: 1,
                      name: this.$t('image') + " 1",
                      image: null
                    },
                    {
                      id: 2,
                      name: this.$t('image') + " 2",
                      image: null
                    },
                    {
                      id: 3,
                      name: this.$t('image') + " 3",
                      image: null
                    },
                    {
                      id: 4,
                      name: this.$t('image') + " 4",
                      image: null
                    }
                  ],
                  with_status: true,
                  status_oc: []
                },
                {
                  id: 3,
                  name: "MECHANICAL INSTALLATION",
                  code: "MM",
                  print: true,
                  withWeekly: false,
                  weeklyRange: [], // rango de fechas
                  weeklyRangeForm: [],
                  count: 5,
                  fixed: true,
                  with_drawing: false,
                  with_activity_document: false,
                  // progress_scheduled: [
                  //   {
                  //     name: "pilings",
                  //     value: 0
                  //   },
                  //   {
                  //     name: "structures",
                  //     value: 0
                  //   },
                  //   {
                  //     name: "modules",
                  //     value: 0
                  //   }
                  // ],
                  images: [
                    {
                      id: 1,
                      name: this.$t('image') + " 1",
                      image: null
                    },
                    {
                      id: 2,
                      name: this.$t('image') + " 2",
                      image: null
                    },
                    {
                      id: 3,
                      name: this.$t('image') + " 3",
                      image: null
                    },
                    {
                      id: 4,
                      name: this.$t('image') + " 4",
                      image: null
                    }
                  ],
                },
                {
                  id: 4,
                  name: "ELECTRICAL INSTALLATION",
                  code: "ME",
                  print: true,
                  withWeekly: false,
                  weeklyRange: [], // rango de fechas
                  weeklyRangeForm: [],
                  count: 5,
                  fixed: true,
                  // drawing_id: null,
                  with_drawing: true,
                  drawing_image: '',
                  with_activity_document: false,
                  images: [
                    {
                      id: 1,
                      name: this.$t('image') + " 1",
                      image: null
                    },
                    {
                      id: 2,
                      name: this.$t('image') + " 2",
                      image: null
                    },
                    {
                      id: 3,
                      name: this.$t('image') + " 3",
                      image: null
                    },
                    {
                      id: 4,
                      name: this.$t('image') + " 4",
                      image: null
                    }
                  ],
                },
                {
                  id: 6,
                  name: "EVACUATION LINE",
                  code: "LEV",
                  print: true,
                  with_drawing: false,
                  with_activity_document: true,
                  activity_image: '',
                  is_evacuation_line: true,
                  images: [
                    {
                      id: 1,
                      name: this.$t('image') + " 1",
                      image: null
                    },
                    {
                      id: 2,
                      name: this.$t('image') + " 2",
                      image: null
                    },
                    {
                      id: 3,
                      name: this.$t('image') + " 3",
                      image: null
                    },
                    {
                      id: 4,
                      name: this.$t('image') + " 4",
                      image: null
                    }
                  ]
                }
            ]
            this.with_pictograms = true
            break;
          case 587 : // arco malpartida
            this.report_sections = [
              {
                id: 1,
                name: "General",
                code: "",
                print: true,
                with_drawing: false,
                with_activity_document: false,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  },
                  {
                    id: 5,
                    name: this.$t('image') + " 5",
                    image: null
                  },
                  {
                    id: 6,
                    name: this.$t('image') + " 6",
                    image: null
                  },
                ]
              },
              {
                id: 2,
                name: "CIVIL WORKS",
                code: "OC",
                print: true,
                with_drawing: true,
                drawing_image: '',
                with_activity_document: false,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ],
                with_status: true,
                status_oc: []
              },
              {
                id: 3,
                name: "MECHANICAL INSTALLATION",
                code: "MM",
                print: true,
                withWeekly: false,
                weeklyRange: [], // rango de fechas
                weeklyRangeForm: [],
                count: 5,
                fixed: true,
                with_drawing: false,
                with_activity_document: false,
                // progress_scheduled: [
                //   {
                //     name: "pilings",
                //     value: 0
                //   },
                //   {
                //     name: "structures",
                //     value: 0
                //   },
                //   {
                //     name: "modules",
                //     value: 0
                //   }
                // ],
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ],
              },
              {
                id: 4,
                name: "ELECTRICAL INSTALLATION",
                code: "ME",
                print: true,
                withWeekly: false,
                weeklyRange: [], // rango de fechas
                weeklyRangeForm: [],
                count: 5,
                fixed: true,
                // drawing_id: null,
                with_drawing: true,
                drawing_image: '',
                with_activity_document: false,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ],
              },
              {
                id: 5,
                name: "SUBSTATION WORKS",
                code: "SET",
                print: true,
                withWeekly: false,
                weeklyRange: [], // rango de fechas
                weeklyRangeForm : [],
                subactivities: [],
                documents: [],
                with_drawing: false,
                with_activity_document: true,
                activity_image: '',
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ],
                with_status: true,
                status_oc: []
              }
            ]
            this.with_pictograms = true
            break;
          case 593 : // CSF LORCA 205 MWp - OHLA
            this.report_sections = [
              {
                id: 1,
                name: "General",
                code: "",
                print: true,
                with_drawing: false,
                with_activity_document: false,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  },
                  {
                    id: 5,
                    name: this.$t('image') + " 5",
                    image: null
                  },
                  {
                    id: 6,
                    name: this.$t('image') + " 6",
                    image: null
                  },
                ]
              },
              {
                id: 2,
                name: "CIVIL WORKS",
                code: "OC",
                print: true,
                with_drawing: true,
                drawing_image: '',
                with_activity_document: false,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ],
                with_status: true,
                status_oc: []
              },
              {
                id: 3,
                name: "MECHANICAL INSTALLATION",
                code: "MM",
                print: true,
                withWeekly: false,
                weeklyRange: [], // rango de fechas
                weeklyRangeForm: [],
                count: 5,
                fixed: true,
                with_drawing: false,
                with_activity_document: false,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ],
              },
              {
                id: 4,
                name: "ELECTRICAL INSTALLATION",
                code: "ME",
                print: true,
                withWeekly: false,
                weeklyRange: [], // rango de fechas
                weeklyRangeForm: [],
                count: 5,
                fixed: true,
                // drawing_id: null,
                with_drawing: true,
                drawing_image: '',
                with_activity_document: false,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ],
              }
            ]
            this.with_pictograms = true
            break;
          case 594 : // CSF LORCA 168 MWp - EIFF
            this.report_sections = [
              {
                id: 1,
                name: "General",
                code: "",
                print: true,
                with_drawing: false,
                with_activity_document: false,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  },
                  {
                    id: 5,
                    name: this.$t('image') + " 5",
                    image: null
                  },
                  {
                    id: 6,
                    name: this.$t('image') + " 6",
                    image: null
                  },
                ]
              },
              {
                id: 2,
                name: "CIVIL WORKS",
                code: "OC",
                print: true,
                with_drawing: true,
                drawing_image: '',
                with_activity_document: false,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ],
                with_status: true,
                status_oc: []
              },
              {
                id: 3,
                name: "MECHANICAL INSTALLATION",
                code: "MM",
                print: true,
                withWeekly: false,
                weeklyRange: [], // rango de fechas
                weeklyRangeForm: [],
                count: 5,
                fixed: true,
                with_drawing: false,
                with_activity_document: false,
                // progress_scheduled: [
                //   {
                //     name: "pilings",
                //     value: 0
                //   },
                //   {
                //     name: "structures",
                //     value: 0
                //   },
                //   {
                //     name: "modules",
                //     value: 0
                //   }
                // ],
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ],
              },
              {
                id: 4,
                name: "ELECTRICAL INSTALLATION",
                code: "ME",
                print: true,
                withWeekly: false,
                weeklyRange: [], // rango de fechas
                weeklyRangeForm: [],
                count: 5,
                fixed: true,
                // drawing_id: null,
                with_drawing: true,
                drawing_image: '',
                with_activity_document: false,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ],
              }
            ]
            this.with_pictograms = true
            break;
          case 595 : // El molino
            this.report_sections = [
              {
                id: 1,
                name: "General",
                code: "",
                print: true,
                with_drawing: false,
                with_activity_document: false,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  },
                  {
                    id: 5,
                    name: this.$t('image') + " 5",
                    image: null
                  },
                  {
                    id: 6,
                    name: this.$t('image') + " 6",
                    image: null
                  },
                ]
              },
              {
                id: 2,
                name: "CIVIL WORKS",
                code: 'OC', //"04.01",
                print: true,
                with_drawing: true,
                drawing_image: '',
                with_activity_document: false,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ],
                with_status: true,
                status_oc: []
              },
              {
                id: 3,
                name: "MECHANICAL INSTALLATION",
                code: 'MM', //"04.02",
                print: true,
                withWeekly: false,
                weeklyRange: [], // rango de fechas
                weeklyRangeForm: [],
                count: 5,
                fixed: true,
                with_drawing: false,
                with_activity_document: false,
                // progress_scheduled: [
                //   {
                //     name: "pilings",
                //     value: 0
                //   },
                //   {
                //     name: "structures",
                //     value: 0
                //   },
                //   {
                //     name: "modules",
                //     value: 0
                //   }
                // ],
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ],
              },
              {
                id: 4,
                name: "ELECTRICAL INSTALLATION",
                code: 'ME', //"04.03",
                print: true,
                withWeekly: false,
                weeklyRange: [], // rango de fechas
                weeklyRangeForm: [],
                count: 5,
                fixed: true,
                // drawing_id: null,
                with_drawing: true,
                drawing_image: '',
                with_activity_document: false,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ],
              },
              {
                id: 5,
                name: "SET PV PLANT ",
                code: 'SET',//"09",
                print: true,
                with_activity_document: false,
                activity_image: '',
                with_drawing: true,
                drawing_image: '',
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ],
                with_status: true,
                status_oc: []
              },
              {
                id: 6,
                name: "EVACUATION LINES",
                code: 'LEV', //"06-07",
                print: true,
                with_drawing: false,
                with_activity_document: true,
                activity_image: '',
                is_evacuation_line: true,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ]
              }

            ]
            this.with_pictograms = true
            break;

          default: // mirtea
            this.report_sections = [
              {
                id: 1,
                name: "General",
                code: "",
                print: true,
                withWeekly: false,
                weeklyRange: [], // rango de fechas
                weeklyRangeForm: [],
                count: 6,
                fixed: true,
                with_drawing: false,
                with_activity_document: false,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  },
                  {
                    id: 5,
                    name: this.$t('image') + " 5",
                    image: null
                  },
                  {
                    id: 6,
                    name: this.$t('image') + " 6",
                    image: null
                  },
                ],
              },
              {
                id: 2,
                name: "CIVIL WORKS",
                code: "OC",
                print: true,
                withWeekly: true,
                weeklyRange: [], // rango de fechas
                weeklyRangeForm: [],
                count: 5,
                fixed: true,
                // drawing_id: null,
                with_drawing: true,
                drawing_image: '',
                with_activity_document: false,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ],
                with_status: true,
                status_oc: []
              },
              {
                id: 3,
                name: "MECHANICAL INSTALLATION",
                code: "MM",
                print: true,
                withWeekly: false,
                weeklyRange: [], // rango de fechas
                weeklyRangeForm: [],
                count: 5,
                fixed: true,
                with_drawing: false,
                with_activity_document: false,
                // progress_scheduled: [
                //   {
                //     name: "pilings",
                //     value: 0
                //   },
                //   {
                //     name: "structures",
                //     value: 0
                //   },
                //   {
                //     name: "modules",
                //     value: 0
                //   }
                // ],
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ],
              },
              {
                id: 4,
                name: "ELECTRICAL INSTALLATION",
                code: "ME",
                print: true,
                withWeekly: false,
                weeklyRange: [], // rango de fechas
                weeklyRangeForm: [],
                count: 5,
                fixed: true,
                // drawing_id: null,
                with_drawing: true,
                drawing_image: '',
                with_activity_document: false,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ],
              },
              {
                id: 5,
                name: "EVACUATION LINE",
                code: "LEV",
                print: false,
                withWeekly: true,
                weeklyRange: [], // rango de fechas
                weeklyRangeForm: [],
                count: 6,
                fixed: false,
                // activity_id: null,
                // subactivity_id: null,
                // document_id: null,
                subactivities: [],
                documents: [],
                with_drawing: false,
                with_activity_document: true,
                activity_image: '',
                is_evacuation_line: true,
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ],
              },
              {
                id: 6,
                name: "SET",
                code: "SET",
                print: false,
                withWeekly: true,
                weeklyRange: [], // rango de fechas
                weeklyRangeForm : [],
                count: 5,
                fixed: false,
                // activity_id: null,
                // subactivity_id: null,
                // document_id: null,
                subactivities: [],
                documents: [],
                with_drawing: false,
                with_activity_document: true,
                activity_image: '',
                images: [
                  {
                    id: 1,
                    name: this.$t('image') + " 1",
                    image: null
                  },
                  {
                    id: 2,
                    name: this.$t('image') + " 2",
                    image: null
                  },
                  {
                    id: 3,
                    name: this.$t('image') + " 3",
                    image: null
                  },
                  {
                    id: 4,
                    name: this.$t('image') + " 4",
                    image: null
                  }
                ],
                with_status: true,
                status_oc: []
              },
            ]
            this.with_pictograms = true

        }
      },

      LoadReport(report) {
        this.week = report.week;

        const fechasFormateadas = report.weeklyRange.map(date => {
            let splitDate = date.split('-')
            return new Date(splitDate[2], splitDate[1] - 1, splitDate[0])
          })

        this.weeklyRangeForm = fechasFormateadas;

        for (const section of this.report_sections) {
          let section_report = Object.values(report.infoTabs).find(s => s.name === section.name)

          section.print = section_report.print
          section.with_activity_document = section_report.with_activity_document
          section.with_drawing = section_report.with_drawing

          if (section.name === 'General') {
            for (let index = 0; index < section_report.images.length; index++) {
              const element = section_report.images[index];
              section.images[index].image = element.image
              section.images[index].name = element.name
              section.images[index].is_activity_document = true

            }
          }

          if (section.code === 'OC') {
            if(section_report.with_activity_document) {
              section.activity_image = section_report.activity_image
            } else {
              section.drawing_image = section_report.drawing_image
            }

            section.status_oc = section_report.status_oc
            section.with_status = section_report.with_status

            for (let index = 0; index < section_report.images.length; index++) {
              const element = section_report.images[index];
              if (! element.image.includes('amazonaws.com/tmp')) {
                section.images[index].image = element.image
                section.images[index].name = element.name
                section.images[index].is_activity_document = true
              }
            }
          }

          if (section.code === 'LEV') {
            section.activity_image = section_report.activity_image
            for (let index = 0; index < section_report.images.length; index++) {
              const element = section_report.images[index];
              if (! element.image.includes('amazonaws.com/tmp')) {
                section.images[index].image = element.image
                section.images[index].name = element.name
                section.images[index].is_activity_document = true
              }

            }
          }

          if (section.code === 'ME') {
            section.drawing_image = section_report.drawing_image
            for (let index = 0; index < section_report.images.length; index++) {
              const element = section_report.images[index];
              if (! element.image.includes('amazonaws.com/tmp')) {
                section.images[index].image = element.image
                section.images[index].name = element.name
                section.images[index].is_activity_document = true
              }

            }
          }

          if (section.code === 'MM') {
            section.drawing_image = section_report.drawing_image
            for (let index = 0; index < section_report.images.length; index++) {
              const element = section_report.images[index];

              if (! element.image.includes('amazonaws.com/tmp')) {
                section.images[index].image = element.image
                section.images[index].name = element.name
                section.images[index].is_activity_document = true
              }
            }
          }

          if (section.code === 'SET') {
            if(section_report.with_activity_document) {
              section.activity_image = section_report.activity_image
            } else {
              section.drawing_image = section_report.drawing_image
            }

            section.status_oc = section_report.status_oc
            section.with_status = section_report.with_status

            for (let index = 0; index < section_report.images.length; index++) {
              const element = section_report.images[index];

              if (! element.image.includes('amazonaws.com/tmp')) {
                section.images[index].image = element.image
                section.images[index].name = element.name
                section.images[index].is_activity_document = true
              }
            }
          }
        }
      },
    },

  };
</script>
